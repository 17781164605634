export class UnexpectedStateError extends Error {
  constructor() {
    super('Unexpected state reached.');
  }
}

export class AbortError extends Error {
  constructor() {
    super('Aborted.');
  }
}
