import { Flex, Button, Dialog, SvgIcon, Text } from '@volue/wave-react';

import { useActions, useAppState } from '@/overmind';

export default function ErrorDialog() {
  const { lastError } = useAppState();
  const { clearLastError } = useActions();

  return (
    <Dialog
      isOpen={lastError !== undefined}
      onIsOpenChange={isOpen => {
        if (!isOpen) clearLastError();
      }}
    >
      <Dialog.Box width="30rem">
        <Dialog.Header>
          <Flex gap="spacingS">
            <SvgIcon
              iconName="error"
              size="medium"
              css={{ color: '$colorRed100' }}
            />
            <Dialog.Title css={{ color: '$colorRed100' }}>
              {'Error'}
            </Dialog.Title>
          </Flex>
          <Dialog.Close
            as={Button}
            variant="ghost"
            shape="circle"
            size="small"
            withLoneIcon
            aria-label="Close"
            className="mAn mLa"
          >
            <SvgIcon iconName="close" size="medium" />
          </Dialog.Close>
        </Dialog.Header>
        <Dialog.Body
          css={{
            wordWrap: 'break-word',
            minHeight: '14rem'
          }}
        >
          <Text as="p">{lastError?.message}</Text>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close as={Button} className="mAn mRa">
            {'Close'}
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Box>
    </Dialog>
  );
}
