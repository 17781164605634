import page from 'page';
import queryString from 'query-string';

export type Params = Record<string, string | undefined>;
export type RouteCtx = { params: Params; path: string };
export type RouteCallback = ({ params, path }: RouteCtx) => void;
export type RouteDefinition =
  | RouteCallback
  | {
      start: RouteCallback;
      exit: RouteCallback;
    };

const isCallback = (route: RouteDefinition): route is RouteCallback => {
  return typeof route === 'function';
};

export default {
  initialize(routes: Record<string, RouteDefinition>) {
    Object.keys(routes).forEach(url => {
      const route = routes[url];

      if (isCallback(route)) {
        page(url, ({ params, querystring: query, path }) => {
          route({
            params: {
              ...params,
              ...queryString.parse(query)
            },
            path
          });
        });
      } else {
        page(url, ({ params, querystring: query, path }) => {
          route.start({
            params: {
              ...params,
              ...queryString.parse(query)
            },
            path
          });
        });

        page.exit(url, ({ params, querystring: query, path }, next) => {
          next();

          route.exit({
            params: {
              ...params,
              ...queryString.parse(query)
            },
            path
          });
        });
      }
    });

    page.start({
      hashbang: false
    });
  },
  goTo({
    url,
    params = {},
    query,
    hash
  }: {
    url: string;
    params?: Params;
    query?: Params;
    hash?: Params;
  }) {
    const urlWithReplacedParams = Object.keys(params).reduce(
      (currentUrl, param) => {
        return currentUrl.replace(`:${param}`, params[param]!);
      },
      url
    );
    const stringQuery = query ? '?' + queryString.stringify(query) : '';
    const hashQuery = hash ? '#' + hash : '';

    page.show(`${urlWithReplacedParams}${stringQuery}${hashQuery}`);
  }
};
