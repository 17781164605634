import { Button } from '@volue/wave-react';
import { useState } from 'react';

import { useActions } from '@/overmind';
import type { ProductContext } from '@/overmind/types';

export const RequestTrialButton = (props: {
  productContext: ProductContext;
}) => {
  const { pageOpened } = useActions();
  const { requestTrial, setProductContext } = useActions().marketplace;
  const [isActing, setIsActing] = useState(false);

  const handleClick = async () => {
    setIsActing(true);
    setProductContext({ ...props.productContext, lastAction: 'requestTrial' });
    await requestTrial(props.productContext);
    setIsActing(false);
    pageOpened({ page: 'thankyou' });
  };

  return (
    <Button
      isLoading={isActing}
      loadingText="Submitting..."
      variant="strong"
      size={'small'}
      isNarrow
      onClick={handleClick}
    >
      {'Request trial'}
    </Button>
  );
};
