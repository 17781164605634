export default {
  get(key: string) {
    const value = localStorage.getItem(key);
    let returnValue = null;
    try {
      returnValue = value ? JSON.parse(value) : null;
    } catch (e) {}

    return returnValue;
  },
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
