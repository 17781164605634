import { AppFrame, Flex, Heading } from '@volue/wave-react';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';

import type { ProductPanelHandle } from '@/components/Shared/ProductPanel/ProductPanel';
import ProductPanel from '@/components/Shared/ProductPanel/ProductPanel';
import { ProductRegionSelect } from '@/components/Shared/ProductRegionSelect';
import ProductSearch from '@/components/Shared/ProductSearch';
import ProductTopBar from '@/components/Shared/ProductTopBar';
import { useAppState } from '@/overmind';
import type { ProductCategory, ProductSummary } from '@/overmind/types';

const Products = () => {
  const { categoryList } = useAppState().marketplace;
  const { scroll, leftLayout } = useAppState();
  const [searchValue, setSearchValue] = useState('');
  const [productRegions, setProductRegions] = useState<string[]>([]);
  const productPanelRefs = useRef<(ProductPanelHandle | null)[]>([]);

  const queryParams = queryString.parse(window.location.search);
  const category = queryParams.category as string;

  useEffect(() => {
    // if not a hash link, scroll to top
    const hash = window.location.hash;
    if (hash === '') {
      document.getElementById('mainFrame')?.scroll(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [productPanelRefs]);

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    for (let i = 0; i < productPanelRefs.current.length; i++) {
      const productPanel = productPanelRefs.current[i];
      const productCard = productPanel?.getProductCard(
        queryParams.productId as string
      );
      if (productCard) {
        productCard.getHeroImageElement().scrollIntoView();
        break;
      }
    }
  }, [productPanelRefs]);

  const ShowTopBar = (scrollValue: number) => {
    let titleText = '';
    for (let i = productPanelRefs.current.length - 1; i >= 0; i--) {
      const y = productPanelRefs.current[i]?.getTitleY();
      if (y && y < 50) {
        titleText = productPanelRefs.current[i]?.getTitleText()!;
        break;
      }
    }
    if (leftLayout)
      return scrollValue > 50 ? <ProductTopBar title={titleText} /> : <></>;
    else
      return scrollValue > 50 ? (
        <AppFrame.HeaderSlotPortal>{titleText}</AppFrame.HeaderSlotPortal>
      ) : (
        <></>
      );
  };

  const productCategories = filterProducts(
    categoryList,
    productRegions,
    searchValue,
    category
  );

  return (
    <>
      <AppFrame.HeaderSlotPortal>
        {ShowTopBar(scroll)}
      </AppFrame.HeaderSlotPortal>
      <Flex
        gap="spacingM"
        flow="column"
        className="w100 pVs pHl"
        css={{ height: '100%', minWidth: '0px' }}
      >
        <ProductSearch onChange={setSearchValue} />
        <ProductRegionSelect onChange={setProductRegions} />
        {productCategories.length === 0 && searchValue !== ''
          ? NoSearchResultsFound(searchValue)
          : DisplayProductPanels(productCategories, productPanelRefs)}
      </Flex>
    </>
  );
};

const DisplayProductPanels = (
  productCategories: ProductCategory[],
  productPanelRefs: React.MutableRefObject<(ProductPanelHandle | null)[]>
) => {
  return productCategories.map((category, index) => {
    return (
      <ProductPanel
        key={category.name}
        ref={element => (productPanelRefs.current[index] = element)}
        productCategory={category}
      />
    );
  });
};

const NoSearchResultsFound = (searchValue: string) => {
  return (
    <>
      <Heading>{`No matches for "${searchValue}"`}</Heading>
      <Heading as="h3">
        {"We're sorry. We were not able to find a match."}
      </Heading>
      <Heading as="h3">
        {
          'Double check your search for any typos or spelling errors or try a different search term.'
        }
      </Heading>
    </>
  );
};

const regionsContainProduct = (
  regions: string[],
  product: ProductSummary
): boolean => {
  if (regions.length === 0 || !regions) return true;
  if (!product.modules || product.modules.length === 0) return false;
  for (let i = 0; i < regions.length; i++) {
    if (product.modules.some(m => m?.includes(regions[i]))) return true;
  }
  return false;
};

const filterProducts = (
  categories: ProductCategory[],
  regions: string[],
  searchValue: string,
  category: string
): ProductCategory[] => {
  const s = searchValue.toLowerCase();
  let categoriesToFilter = categories;
  if ((category ?? '') !== '') {
    categoriesToFilter = categories.filter(
      c => c.name.replaceAll(' ', '') === category
    );
  }
  let f = categoriesToFilter.map(c => ({
    name: c.name,
    products: c.products.filter(
      p =>
        (p.name?.toLowerCase().includes(s) ||
          p.description?.toLowerCase().includes(s)) &&
        regionsContainProduct(regions, p)
    )
  }));
  f = f.filter(c => c.products.length !== 0);
  return f;
};

export default Products;
