import type { AxiosResponse } from 'axios';

import type {
  ProductContactMeResponse,
  ProductCategory,
  ProductContext,
  ProductQuoteResponse,
  ProductTrialResponse,
  InsightProductDetails,
  ProductRegion,
  UserClaim,
  AuthorizedUserInfo,
  OnBoardingRequest
} from '@/overmind/types';
import { OnBoardingRequestStatus } from '@/overmind/types';

import type { Api, ApiConfig } from './apiFactory';
import apiFactory from './apiFactory';

let api: Api;

export default {
  initialize(
    config: ApiConfig,
    responseInterceptor: (response: AxiosResponse) => void
  ) {
    api = apiFactory(config, responseInterceptor);
  },
  setToken(newToken: string) {
    api.setToken(newToken);
  },
  getProducts(productLine: string | undefined) {
    // if (productLine) {
    return api.get<ProductCategory[]>(
      `/api/products/product-lines/${productLine}`
    );
    // } else {
    //  return api.get<ProductCategory[]>(`/api/products`);
    // }
  },
  getProductsAndMyProducts(productLine: string | undefined, tenant: string) {
    return api.get<ProductCategory[]>(
      `/api/products/subscriptions/${productLine}?tenant=${tenant}`
    );
  },
  getMyProducts(tenant: string) {
    return api.get<ProductCategory[]>(
      `/api/products/subscriptions?tenant=${tenant}`
    );
  },
  getProductRegions() {
    return api.get<ProductRegion[]>('api/product-regions');
  },
  getAuthorizedUserInfo() {
    return api.get<AuthorizedUserInfo>('api/me');
  },
  getUser() {
    const uri = __APP_URL__ + '/auth/user';
    return api.get<UserClaim[]>(uri);
  },
  getQuote(productContext: ProductContext) {
    return api.post<ProductQuoteResponse>(
      '/api/products/get-quote',
      productContext
    );
  },
  async getOnBoardingStatus() {
    const requests = await api.get<OnBoardingRequest[]>(
      'api/on-boarding-requests'
    );
    if (!requests || requests.length === 0)
      return OnBoardingRequestStatus.unknown;
    return requests.some(r => r.status === OnBoardingRequestStatus.pending)
      ? OnBoardingRequestStatus.pending
      : OnBoardingRequestStatus.unknown;
  },
  registerOnBoarding(companyName: string) {
    return api.post<OnBoardingRequest>('api/on-boarding-requests', {
      companyName,
      status: OnBoardingRequestStatus.pending
    });
  },
  requestTrial(productContext: ProductContext, tenant: string) {
    return api.post<ProductTrialResponse>(
      '/api/products/request-trial?tenant=' + tenant,
      productContext
    );
  },
  contactMe(productContext: ProductContext) {
    return api.post<ProductContactMeResponse>(
      '/api/contact-me',
      productContext
    );
  },
  async getProductsById(id: string) {
    return await api.get<ProductCategory[]>(`/api/products/${id}`);
  },
  async getInsightProductDetails(id: string) {
    return await api.get<InsightProductDetails>(
      `/api/products/insight-product-details/${id}`
    );
  }
};
