const VISIBILITY_CHANGE_EVENT = 'visibilitychange';

export const VISIBILITY_STATES = {
  HIDDEN: 'HIDDEN',
  VISIBLE: 'VISIBLE'
} as const;

type VisilibityState = keyof typeof VISIBILITY_STATES;
type VisibilityHandler = (state: VisilibityState) => void;

const listeners: [VisibilityHandler, () => void][] = [];

export default class AppVisibility {
  static get currentState() {
    if (!document.visibilityState) {
      return VISIBILITY_STATES.VISIBLE;
    }

    if (document.visibilityState === 'hidden') {
      return VISIBILITY_STATES.HIDDEN;
    }

    return VISIBILITY_STATES.VISIBLE;
  }

  static addChangeListener(handler: VisibilityHandler) {
    const callback = () => handler(AppVisibility.currentState);
    listeners.push([handler, callback]);
    document.addEventListener(VISIBILITY_CHANGE_EVENT, callback, false);
  }

  static removeChangeListener(handler: VisibilityHandler) {
    const listenerIndex = listeners.findIndex(pair => pair[0] === handler);

    if (~listenerIndex) {
      const callback = listeners[listenerIndex][1];
      document.removeEventListener(VISIBILITY_CHANGE_EVENT, callback, false);
      listeners.splice(listenerIndex, 1);
    }
  }
}
