import { Box, MultiSelectMenu, Label } from '@volue/wave-react';
import type { Dispatch, SetStateAction } from 'react';

import { useAppState } from '@/overmind';

function ProductRegionSelect(props: {
  onChange: Dispatch<SetStateAction<string[]>>;
}) {
  const { productRegions } = useAppState().marketplace;
  const { leftLayout } = useAppState();

  return (
    <Box
      css={{
        position: 'absolute',
        zIndex: '101',
        top: '22px',
        right: leftLayout ? '250px' : '450px',
        minWidth: '250px',
        cursor: 'pointer'
      }}
    >
      <MultiSelectMenu size="small" onValueChange={props.onChange}>
        <MultiSelectMenu.Trigger
          aria-label="Tags"
          placeholder="Filter by regions"
          withLabels
          endElement={<MultiSelectMenu.Clear label="Clear" />}
        />
        <MultiSelectMenu.Content>
          {productRegions.map(r => (
            <MultiSelectMenu.Item
              key={r.symbol}
              value={r.symbol}
              css={{ cursor: 'pointer' }}
              endElement={
                <Label size="small" circular variant="subtle">
                  {r.symbol}
                </Label>
              }
            >
              {r.name}
            </MultiSelectMenu.Item>
          ))}
        </MultiSelectMenu.Content>
      </MultiSelectMenu>
    </Box>
  );
}

export { ProductRegionSelect };
export default ProductRegionSelect;
