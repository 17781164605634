import queryString from 'query-string';
import type { ComponentType } from 'react';

import match from '@/common/utils/match';
import { useActions, useAppState } from '@/overmind';
import type { LastError } from '@/overmind';
import type { Route } from '@/overmind/machines/router';

import AppLayoutLeft from '../AppLayoutLeft';
import AppLayoutTop from '../AppLayoutTop';
import ErrorDialog from '../Dialogs/ErrorDialog';
import SessionExpirationDialog from '../Dialogs/SessionExpirationDialog';
// import Home from '../Pages/Home';
// import Marketplace from '../Pages/Marketplace';
import MyProducts from '../Pages/MyProducts';
// import MySubscriptions from '../Pages/MySubscriptions';
import Onboard from '../Pages/Onboard';
import Products from '../Pages/Products';
import ThankYou from '../Pages/ThankYou';
import UserSettings from '../Pages/UserSettings';
import Spinner from '../Shared/Spinner';

const pages: Record<Route, ComponentType> = {
  home: Products,
  // marketplace: Marketplace,
  products: Products,
  myproducts: MyProducts,
  // mysubscriptions: MySubscriptions,
  usersettings: UserSettings,
  thankyou: ThankYou
};

const hasViewableError = (lastError: LastError | undefined) => {
  return lastError !== undefined && lastError.code !== 401;
};

export default function App() {
  const { router, signedIdUser, signedIdUserReady, lastError } = useAppState();
  const { signIn, changeLayout } = useActions();

  // The user must be logged in
  if (signedIdUserReady && signedIdUser === undefined) {
    signIn();
    return !hasViewableError(lastError) ? (
      <Spinner isSigningIn />
    ) : (
      <ErrorDialog />
    );
  }
  if (!signedIdUserReady) {
    return !hasViewableError(lastError) ? (
      <Spinner isSigningIn />
    ) : (
      <ErrorDialog />
    );
  }

  if ((signedIdUser?.tenants ?? []).length === 0) {
    return <Onboard />;
  }

  const content = match(router, {
    VALID_PAGE: ({ currentRoute }) => {
      const Page = pages[currentRoute];
      return <Page />;
    },
    PAGE_NOT_FOUND: () => (
      <>
        <div className="pAl gray75">{'Sorry, page not found'}</div>
      </>
    )
  });

  const queryParams = queryString.parse(window.location.search);
  const isLeftLayout = queryParams.v !== '2';
  changeLayout(isLeftLayout);

  return (
    <>
      {isLeftLayout ? (
        <AppLayoutLeft>{content}</AppLayoutLeft>
      ) : (
        <AppLayoutTop>{content}</AppLayoutTop>
      )}
      <ErrorDialog />
      <SessionExpirationDialog />
    </>
  );
}
