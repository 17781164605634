import type {
  ProductCategory,
  ProductContext,
  ProductQuoteResponse,
  ProductRegion,
  ProductTrialResponse
} from '@/overmind/types';

export type MarketplaceState = {
  productContext: ProductContext;
  quotes: Record<string, ProductQuoteResponse>;
  trials: Record<string, ProductTrialResponse>;
  categoryList: ProductCategory[];
  productRegions: ProductRegion[];
};

export const state: MarketplaceState = {
  productContext: {
    id: undefined,
    productOfInterest: undefined,
    lastAction: undefined,
    productLine: undefined
  },
  trials: {},
  quotes: {},
  categoryList: [],
  productRegions: []
};
