import type { HubConnection } from '@microsoft/signalr';
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
  HubConnectionState
} from '@microsoft/signalr';
import { CompositeDisposable } from 'sb-event-kit';

import AppVisibility, { VISIBILITY_STATES } from '@/common/utils/AppVisibility';

import type { Logger } from './logger';

const hubSubscriptions = new CompositeDisposable();

type Options = {
  hubName: string;
  onHubConnectionError: (payload: { hubName: string; error: Error }) => void;
  onHubConnectionClose?: (hubName: string) => void;
  onHubConnectionOpen?: (hubName: string) => void;
  onHubConnectionOpenError?: (payload: {
    hubName: string;
    error: Error;
  }) => void;
  logger: Logger;
};

let connection: HubConnection;
let options: Options;

export default {
  initialize(effectOptions: Options) {
    options = effectOptions;

    const { hubName } = options;

    connection = new HubConnectionBuilder()
      .withUrl(`${__APP_URL__}/${hubName}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(
        process.env.NODE_ENV === 'development'
          ? LogLevel.Debug
          : LogLevel.Information
      )
      .withAutomaticReconnect()
      .build();

    connection.onclose(error => {
      if (error != null) {
        const isAppVisible =
          AppVisibility.currentState === VISIBILITY_STATES.VISIBLE;

        if (isAppVisible) {
          options.onHubConnectionError({
            hubName,
            error
          });
        } else {
          // while the app is hidden (i.e. on a hidden tab),
          // only fire the `onHubError` if the app becomes visible again

          requestAnimationFrame(() => {
            options.onHubConnectionError({ hubName, error });
          });
        }

        return;
      }

      options.onHubConnectionClose?.(hubName);
    });
  },
  async connect() {
    if (
      connection.state === HubConnectionState.Connected ||
      connection.state === HubConnectionState.Connecting
    ) {
      options.logger.warn('SignalR connection already started.');
      return;
    }

    try {
      await connection.start();

      options.onHubConnectionOpen?.(options.hubName);
    } catch (error) {
      options.onHubConnectionOpenError?.({
        hubName: options.hubName,
        error: error as Error
      });

      throw error;
    }
  },
  async disconnect() {
    hubSubscriptions.dispose();

    await connection.stop();
  },
  addSubscriptions(handlerByEventName: Record<string, (data: any) => void>) {
    const subscriptionsToDispose = new CompositeDisposable();

    Object.keys(handlerByEventName).forEach(eventName => {
      connection.on(eventName, handlerByEventName[eventName]);

      subscriptionsToDispose.add(() => {
        connection.off(eventName);
      });
    });

    hubSubscriptions.add(subscriptionsToDispose);

    return () => {
      // @ts-expect-error CompositeDisposable should be accepted
      hubSubscriptions.delete(subscriptionsToDispose);
      subscriptionsToDispose.dispose();
    };
  }
};
