import {
  Flex,
  Placement,
  SelectMenu,
  SingleSelectMenu,
  SvgIcon
} from '@volue/wave-react';

import { useActions, useAppState } from '@/overmind';

const TenantSwitch = () => {
  const { signedIdUser } = useAppState();
  const { setActiveTenant, pageReload } = useActions();

  return (
    <Flex css={{ cursor: 'pointer' }}>
      <SvgIcon
        style={{ marginLeft: '8px', marginRight: '-10px' }}
        iconName="building"
      />
      <SingleSelectMenu
        defaultValue={signedIdUser?.activeTenant?.name}
        onValueChange={value => {
          const tenants = signedIdUser?.tenants?.filter(t => t.name === value);
          if (!tenants) return;
          setActiveTenant(tenants[0].id);
          pageReload();
        }}
      >
        <SingleSelectMenu.Trigger
          as={Flex}
          css={{ width: '100%', border: 'none', paddingLeft: '0.5rem' }}
          placeholder={signedIdUser?.activeTenant?.name}
        >
          {signedIdUser && <SelectMenu.Icon />}
        </SingleSelectMenu.Trigger>
        <SingleSelectMenu.Content
          placement={Placement.RIGHT}
          css={{ minWidth: '15rem' }}
        >
          {
            // @ts-ignore
            signedIdUser?.tenants?.map(tenant => (
              <SingleSelectMenu.Item
                css={{ cursor: 'pointer' }}
                key={tenant.id}
                value={tenant.name}
                startElement={
                  <SingleSelectMenu.ItemIndicator>
                    <SvgIcon iconName="check" />
                  </SingleSelectMenu.ItemIndicator>
                }
              >
                {tenant.name}
              </SingleSelectMenu.Item>
            ))
          }
        </SingleSelectMenu.Content>
      </SingleSelectMenu>
    </Flex>
  );
};

export default TenantSwitch;
