import type { State } from '@/overmind';

type Match<S extends State, R = any> = {
  [Current in S['current']]: (
    current: S extends { current: Current } ? S : never
  ) => R;
};

export default function match<S extends State, T extends Match<S>>(
  state: S,
  matches: T & {
    [K in keyof T]: S extends State
      ? K extends S['current']
        ? T[K]
        : never
      : never;
  }
): {
  [K in keyof T]: T[K] extends (...args: any[]) => infer R ? R : never;
}[keyof T];
// eslint-disable-next-line no-redeclare
export default function match() {
  const state = arguments[0];
  const matches = arguments[1];

  if (matches) {
    return matches[state.current](state);
  }

  // @ts-ignore Too complex for TS to do this correctly
  return matches => matches[state.current](state);
}
