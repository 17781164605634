import {
  AppFrame,
  Avatar,
  Box,
  Flex,
  FlexItem,
  HeaderNavList,
  Heading,
  Logo,
  Menu,
  Placement,
  Popover,
  styled,
  SvgIcon,
  Text
} from '@volue/wave-react';
import type { ReactNode } from 'react';

import { useActions, useAppState } from '@/overmind';

const UserAvatar = styled(Avatar, {
  mx: '$spacingS'
});

export default function AppLayoutTop({ children }: { children: ReactNode }) {
  const { signOut, mainScrolled } = useActions();
  const { signedIdUser } = useAppState();
  const { pageOpened } = useActions();
  return (
    <AppFrame className="vh100">
      <AppFrame.Header>
        <Flex main="space-between" paddingLeft="spacingM">
          <FlexItem as={Flex} shrinkPastContentSize>
            <Logo
              variant={{
                '@initial': 'icon',
                '@mqLargeAndUp': 'wordmark'
              }}
            />
            <Heading margin="none" variant="delta" overflowStrategy="truncate">
              <AppFrame.HeaderSlot />
            </Heading>
          </FlexItem>
          <HeaderNavList aria-label="Main menu">
            {/* Main menu */}
            <Popover>
              <Popover.Trigger
                as={HeaderNavList.Item}
                endElement={<HeaderNavList.ChevronIcon />}
              >
                {'Menu'}
              </Popover.Trigger>
              <Popover.Content
                anchorOffset={0}
                as={HeaderNavList.PopoverContent}
              >
                <HeaderNavList
                  flow="column"
                  size="small"
                  aria-label="Main menu navigation"
                >
                  <HeaderNavList.Item
                    startElement={<SvgIcon iconName="trendingUp" />}
                    onClick={() =>
                      pageOpened({
                        page: 'products',
                        query: { productLine: 'Insight', v: '2' }
                      })
                    }
                  >
                    <Popover.Close>{'Insight'}</Popover.Close>
                  </HeaderNavList.Item>
                  <HeaderNavList.Item
                    startElement={<SvgIcon iconName="download" />}
                    onClick={() =>
                      pageOpened({
                        page: 'myproducts',
                        query: { v: '2' }
                      })
                    }
                  >
                    <Popover.Close>{'My applications'}</Popover.Close>
                  </HeaderNavList.Item>
                </HeaderNavList>
              </Popover.Content>
            </Popover>
            {/* User menu */}
            <Menu>
              <Menu.Trigger as={HeaderNavList.Item}>
                <UserAvatar of={signedIdUser?.displayName!}>
                  <Avatar.Initials color="accentSubtle" />
                </UserAvatar>
              </Menu.Trigger>
              <Menu.Content placement={Placement.BOTTOM_RIGHT}>
                <Flex gap="spacingM" paddingY="spacingS" paddingX="spacingM">
                  <UserAvatar of={signedIdUser?.displayName!} size="medium">
                    <Avatar.Initials color="accentSubtle" />
                  </UserAvatar>
                  <Box>
                    {signedIdUser?.displayName}
                    <Text
                      as="div"
                      variant="small"
                      color="foregroundNeutralSubtle"
                    >
                      {signedIdUser?.userName}
                    </Text>
                  </Box>
                </Flex>
                <Menu.Separator />
                <Menu.Item
                  startElement={<SvgIcon iconName="download" />}
                  onClick={() =>
                    pageOpened({
                      page: 'myproducts',
                      query: { v: '2' }
                    })
                  }
                >
                  {'My applications'}
                </Menu.Item>
                <Menu.Separator />
                <Menu.Item intent="danger" onSelect={() => signOut()}>
                  {'Log Out'}
                </Menu.Item>
              </Menu.Content>
            </Menu>
          </HeaderNavList>
        </Flex>
      </AppFrame.Header>
      <AppFrame.Main
        id="mainFrame"
        onScroll={event => {
          mainScrolled(event.currentTarget.scrollTop);
        }}
        paddingY={{ '@initial': 'spacingM', '@mqMediumAndUp': 'spacingL' }}
        paddingX="spacingM"
        css={{
          backgroundColor: '$colorGray5',
          display: 'grid',
          placeItems: 'center'
        }}
      >
        {children}
      </AppFrame.Main>
    </AppFrame>
  );
}
