import { catchError, pipe } from 'overmind';

import type { Context } from '@/overmind';
import type { RouteCtx } from '@/overmind/effects/router';

export const loadProducts = pipe(
  async ({ state, effects }: Context) => {
    state.isLoading = true;
    const tenant = state.signedIdUser?.activeTenant?.id ?? '';
    state.myProducts.categoryList = await effects.api.getMyProducts(tenant);
    state.isLoading = false;
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

export const myProductsRouted = (
  { actions }: Context,
  { params }: RouteCtx
) => {
  actions.internal.setRoute({ route: 'myproducts', params });
  actions.myProducts.loadProducts();
};
