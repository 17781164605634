import { Flex, Heading } from '@volue/wave-react';
import { useEffect } from 'react';

import ProductPanel from '@/components/Shared/ProductPanel';
import { useAppState } from '@/overmind';

const MyProducts = () => {
  const { categoryList } = useAppState().myProducts;
  const { isLoading } = useAppState();

  useEffect(() => {
    // scroll to top
    document.getElementById('mainFrame')?.scroll(0, 0);
  }, []);

  return (
    <Flex
      gap="spacingM"
      flow="column"
      className="w100 pVs pHl"
      css={{ height: '100%' }}
    >
      {categoryList.length > 0 || isLoading ? (
        categoryList.map(category => {
          return (
            <ProductPanel key={category.name} productCategory={category} />
          );
        })
      ) : (
        <>
          <Heading css={{ paddingTop: '23px' }}>{`No applications`}</Heading>
          <Heading as="h3">
            {'You currently do not have any application subscriptions.'}
          </Heading>
        </>
      )}
    </Flex>
  );
};

export default MyProducts;
