import axios from 'axios';
import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

import { AbortError } from '@/overmind/errors';

export type Params = Record<string, string>;

export type ApiConfig = AxiosRequestConfig;

export type Api = {
  setToken: (newToken: string) => void;
  get: <T>(
    path: string,
    params?: Params,
    config?: Omit<AxiosRequestConfig, 'params' | 'url'>
  ) => Promise<T>;
  post: <T>(
    path: string,
    body: any,
    config?: Omit<AxiosRequestConfig, 'data' | 'url'>
  ) => Promise<T>;
  patch: <T>(
    path: string,
    body: any,
    config?: Omit<AxiosRequestConfig, 'data' | 'url'>
  ) => Promise<T>;
  put: <T>(
    path: string,
    body: any,
    config?: Omit<AxiosRequestConfig, 'data' | 'url'>
  ) => Promise<T>;
  delete: <T>(
    path: string,
    params?: Params,
    config?: Omit<AxiosRequestConfig, 'params' | 'url'>
  ) => Promise<T>;
  request: <T>(config: AxiosRequestConfig) => Promise<T>;
};

const handleError = (error: AxiosError) => {
  if (axios.isCancel(error)) {
    throw new AbortError();
  }

  throw error;
};

const handleResponse = (response: AxiosResponse) => response.data;

export default (
  config?: ApiConfig,
  responseInterceptor?: (response: AxiosResponse) => void
) => {
  let token: string | undefined;

  const axiosInstance = axios.create(config);
  axiosInstance.defaults.withCredentials = true;
  axiosInstance.defaults.headers.common[__XCRSF__] = __XCRSF_VALUE__;
  if (responseInterceptor) {
    axiosInstance.interceptors.response.use((response: AxiosResponse) => {
      responseInterceptor(response);
      return response;
    });
  }

  const withAuthorizationHeader = (config: ApiConfig = {}) => ({
    ...config,
    headers: {
      ...(config.headers || {}),
      ...(token ? { Authorization: `Bearer ${token}` } : {})
    }
  });

  const api: Api = {
    setToken(newToken) {
      token = newToken;
    },
    get(path, params, config) {
      return axiosInstance
        .get(path, {
          params,
          ...withAuthorizationHeader(config)
        })
        .then(handleResponse)
        .catch(handleError);
    },
    post(path, body, config) {
      return axiosInstance
        .post(path, body, withAuthorizationHeader(config))
        .then(handleResponse)
        .catch(handleError);
    },
    patch(path, body, config) {
      return axiosInstance
        .patch(path, body, withAuthorizationHeader(config))
        .then(handleResponse)
        .catch(handleError);
    },
    put(path, body, config) {
      return axiosInstance
        .put(path, body, withAuthorizationHeader(config))
        .then(handleResponse)
        .catch(handleError);
    },
    delete(path, params, config) {
      return axiosInstance
        .delete(path, {
          params,
          ...withAuthorizationHeader(config)
        })
        .then(handleResponse)
        .catch(handleError);
    },
    request(config) {
      return axiosInstance
        .request(withAuthorizationHeader(config))
        .then(handleResponse)
        .catch(handleError);
    }
  };

  return api;
};
