import type { Context } from '.';
import type { ChangeRoutePayload } from './machines/router';
import type { AuthorizedUserTenantInfo } from './types';

const UserNameClaim = 'preferred_username';
const DisplayUserNameClaim = 'name';
const SessionIdClaimName = 'sid';
const SessionExpiryClaim = 'bff:session_expires_in';

export const hubConnectionErrorReceived = (
  { effects, state }: Context,
  { error }: { error: Error }
) => {
  effects.logger.error(error);
  state.signalR.send('CONNECTION_ERROR', error.message);
};

export const hubConnectionOpened = ({ effects }: Context, hubName: string) => {
  effects.logger.info(`Connection to ${hubName} opened`);
};

export const setRoute = ({ state }: Context, payload: ChangeRoutePayload) => {
  state.currentPage = { page: payload.route, query: payload.params };
  state.router.send('CHANGE_ROUTE', payload);
};

export const notFoundRouted = ({ state }: Context) => {
  state.router.send('NOT_FOUND_ROUTED');
};

export const getSignedInUser = async ({ state, effects }: Context) => {
  state.isSigningIn = true;
  const authState = await effects.api.getUser();
  const authorizedUserInfo = await effects.api.getAuthorizedUserInfo();
  state.integrations = {
    identityAdminUrl: authorizedUserInfo.identityAdminUrl
  };
  if ((authorizedUserInfo.tenants?.length ?? 0) === 0) {
    const onBoardingState = await effects.api.getOnBoardingStatus();
    state.onBoardingStatus = {
      statusOnBackend: onBoardingState,
      hasBeenSentOnFrontend: false
    };
  }
  const activeTenantId = localStorage.getItem('activeTenant');
  const activeTenant =
    authorizedUserInfo.tenants?.find(t => t.id === activeTenantId) ??
    authorizedUserInfo.tenants?.[0];
  localStorage.setItem('activeTenant', activeTenant?.id ?? '');
  if (authState?.length) {
    state.signedIdUser = {
      sid: authState.find(c => c.type === SessionIdClaimName)?.value || '',
      userName: authState.find(c => c.type === UserNameClaim)?.value || '',
      displayName:
        authState.find(c => c.type === DisplayUserNameClaim)?.value || '',
      tenants: authorizedUserInfo?.tenants,
      activeTenant: activeTenant as AuthorizedUserTenantInfo
    };
    state.signedIdUserReady = true;
    const expiresInSeconds = Number(
      authState.find(c => c.type === SessionExpiryClaim)?.value ?? '0'
    );
    state.sessionExpiryInSeconds = expiresInSeconds;
    state.sessionExpiry = new Date(Date.now() + expiresInSeconds * 1000);
  }
  state.isSigningIn = false;
};
