import { Flex, Button, Dialog, SvgIcon, Text, Box } from '@volue/wave-react';
import { useState } from 'react';

import { useInterval } from '@/common/hooks';
import { useActions, useAppState } from '@/overmind';

const ONE_MINUTE = 60 * 1000;
const ONE_SECOND = 1000;

export default function SessionExpirationDialog() {
  const { signedIdUser, sessionExpiry } = useAppState();
  const { signOut, extendSession } = useActions();
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [showDialog, setShowDialog] = useState(false);

  useInterval(() => {
    if (
      signedIdUser &&
      sessionExpiry &&
      sessionExpiry <= new Date(Date.now() + ONE_MINUTE)
    ) {
      if (sessionExpiry! < new Date(Date.now())) {
        setShowDialog(false);
        signOut();
      } else {
        setShowDialog(true);
        setSecondsLeft(Math.floor((Number(sessionExpiry) - Date.now()) / 1000));
      }
    } else {
      setShowDialog(false);
    }
  }, ONE_SECOND);

  return (
    <Dialog isOpen={signedIdUser && showDialog}>
      <Dialog.Box width="30rem">
        <Dialog.Header>
          <Flex gap="spacingS">
            <SvgIcon
              iconName="timeAlarm"
              size="medium"
              css={{ color: '$colorYellow100' }}
            />
            <Dialog.Title css={{ color: '$colorYellow100' }}>
              {'Session Expiration Warning'}
            </Dialog.Title>
          </Flex>
          <Dialog.Close
            as={Button}
            variant="ghost"
            shape="circle"
            size="small"
            withLoneIcon
            aria-label="Close"
            className="mAn mLa"
          >
            <SvgIcon iconName="close" size="medium" />
          </Dialog.Close>
        </Dialog.Header>
        <Dialog.Body
          css={{
            wordWrap: 'break-word',
            minHeight: '14rem'
          }}
        >
          <Box
            css={{
              padding: '$spacingL'
            }}
          >
            <Text as="div" fontSize={'gamma'}>
              {'Your session is about to expire!'}
            </Text>
            <Text as="div" fontSize={'delta'} className="mTl">
              {'You will be logged out in : '}
              <Text fontSize={'delta'} className="mTl" fontWeight="semibold">
                {secondsLeft}
                {' seconds'}
              </Text>
              <Text as="div" fontSize={'delta'}>
                {'Do you want to stay logged in?'}
              </Text>
            </Text>
          </Box>
        </Dialog.Body>
        <Dialog.Footer>
          <Flex main="end">
            <Button
              id="extend-session"
              size="small"
              onClick={() => extendSession()}
            >
              {'Stay logged in'}
            </Button>
            <Button
              variant="outline"
              id="sign-out"
              size="small"
              onClick={() => signOut()}
            >
              {'Sign out'}
            </Button>
          </Flex>
        </Dialog.Footer>
      </Dialog.Box>
    </Dialog>
  );
}
