import { Box, Grid, Heading } from '@volue/wave-react';
import { forwardRef, useRef, useImperativeHandle } from 'react';

import ProductCard from '@/components/Shared/ProductCard';
import type { ProductCategory } from '@/overmind/types';

import type { ProductCardHandle } from '../ProductCard/ProductCard';

export type ProductPanelHandle = {
  getTitleY: () => number;
  getTitleText: () => string;
  getProductCard: (productId: string) => ProductCardHandle | null;
};

type Props = {
  productCategory: ProductCategory;
};

const ProductPanel = forwardRef<ProductPanelHandle, Props>((props, ref) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const productCardRefs = useRef<(ProductCardHandle | null)[]>([]);

  useImperativeHandle(ref, () => ({
    getTitleY() {
      const rc = titleRef.current?.getBoundingClientRect();
      if (rc?.bottom) return rc?.bottom!;
      else return 0;
    },
    getTitleText() {
      return props.productCategory.name;
    },
    getProductCard(productId: string) {
      for (let i = 0; i < productCardRefs.current.length; i++) {
        const productCard = productCardRefs.current[i];
        if (productCard?.getProductId() === productId) {
          return productCard;
        }
      }
      return null;
    }
  }));

  return (
    <Box key={props.productCategory.name}>
      <Box className="pAs">
        <Heading as="h2" variant="beta" ref={titleRef}>
          <div id={props.productCategory.name.replace(/\s/g, '')} />
          {props.productCategory.name}
        </Heading>
      </Box>
      <Grid
        data-testid="marketplace-page"
        gap="spacingM"
        css={{
          gridTemplateColumns: 'repeat(auto-fill,minmax(300px, 0.7fr))'
        }}
      >
        {props.productCategory.products.map((productSummary, index) => {
          return (
            <ProductCard
              key={productSummary.id}
              ref={element => (productCardRefs.current[index] = element)}
              productSummary={productSummary}
            />
          );
        })}
      </Grid>
    </Box>
  );
});

export default ProductPanel;
