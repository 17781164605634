import type { Statemachine } from 'overmind';
import { statemachine } from 'overmind';

type States =
  | {
      current: 'DISCONNECTED';
    }
  | {
      current: 'CONNECTING';
    }
  | {
      current: 'CONNECTED';
    }
  | {
      current: 'ERROR';
      error: string;
    };

type Events =
  | {
      type: 'CONNECT';
    }
  | {
      type: 'CONNECTION_ERROR';
      data: string;
    }
  | {
      type: 'CONNECTION_SUCCESS';
    };

export type SignalRMachine = Statemachine<States, Events>;

export const signalRMachine = statemachine<States, Events>({
  DISCONNECTED: {
    CONNECT: () => ({
      current: 'CONNECTING'
    })
  },
  CONNECTING: {
    CONNECTION_ERROR: error => ({
      current: 'ERROR',
      error
    }),
    CONNECTION_SUCCESS: () => ({
      current: 'CONNECTED'
    })
  },
  CONNECTED: {
    CONNECTION_ERROR: error => ({
      current: 'ERROR',
      error
    })
  },
  ERROR: {
    CONNECT: () => ({
      current: 'CONNECTING'
    })
  }
});

export const createSignalRMachine = () => {
  return signalRMachine.create({ current: 'DISCONNECTED' });
};
