import { Button } from '@volue/wave-react';
import { useState } from 'react';

import { useActions } from '@/overmind';
import type { ProductContext } from '@/overmind/types';

export const GetQuoteButton = (props: { productContext: ProductContext }) => {
  const { getQuote, setProductContext } = useActions().marketplace;
  const { pageOpened } = useActions();
  const [isActing, setIsActing] = useState(false);

  const handleClick = async () => {
    setIsActing(true);
    setProductContext({ ...props.productContext, lastAction: 'getQuote' });
    await getQuote(props.productContext);
    setIsActing(false);
    pageOpened({
      page: 'thankyou'
    });
  };

  return (
    <Button
      isLoading={isActing}
      loadingText="Submitting..."
      variant="ghost"
      size={'small'}
      isNarrow
      onClick={handleClick}
    >
      {'Get quote'}
    </Button>
  );
};
