import {
  Box,
  MotionBox,
  Card,
  Flex,
  FlexItem,
  Logo,
  Spinner,
  Text
} from '@volue/wave-react';

import { useAppState } from '@/overmind';

import styles from './Spinner.module.css';

type Props = {
  isLoading?: boolean | undefined;
  nested?: boolean | undefined;
  isSigningIn?: boolean | undefined;
};

export default function SpinnerComponent({
  isLoading,
  nested,
  isSigningIn
}: Props) {
  const appState = useAppState();

  const showSpinner =
    isLoading === undefined
      ? appState.isLoading || appState.isSigningIn
      : isLoading;
  const overlay = (
    <>
      {showSpinner ? (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ delay: 1.0, duration: 0.2 }}
          className={nested ? styles.spinnerNested : styles.spinner}
          css={{ padding: '$spacingM', textAlign: 'center' }}
        >
          <Box className={styles['spinner-box']}>
            <Spinner inline color="foregroundInfoModerate" />
            <Box css={{ color: '$colorWhite' }}>
              {'Please wait'}
              <span className="txtAnimatedEllipsis" />
            </Box>
          </Box>
        </MotionBox>
      ) : (
        ''
      )}
    </>
  );
  const signIn = (
    <Flex
      style={{ backgroundColor: '#082b33', width: '100vw', height: '100vh' }}
      flow="column"
      cross="center"
      main="center"
    >
      <Flex
        flow="column"
        cross="center"
        main="center"
        style={{ height: '700px' }}
      >
        <div style={{ color: 'white' }}>
          <Logo />
        </div>
        <FlexItem flex="1" />
        <Card style={{ width: '350px', padding: '20px' }}>
          <Flex flow="row" cross="center" main="center">
            <div style={{ width: '50px' }}>
              <Spinner inline size="large" />
            </div>
            <Text
              as="div"
              fontSize="zeta"
              lineHeight="base"
              fontFamily="display"
              align="center"
              className="mBm"
            >
              {'Please wait while the marketplace is being loaded'}
              <span className="txtAnimatedEllipsis" />
            </Text>
          </Flex>
        </Card>
        <FlexItem flex="1" />
        <FlexItem flex="1" />
      </Flex>
    </Flex>
  );

  return <>{showSpinner ? (isSigningIn ? signIn : overlay) : ''}</>;
}
