import {
  Box,
  Button,
  Flex,
  FormField,
  SvgIcon,
  TextInput
} from '@volue/wave-react';
import type { Dispatch, SetStateAction } from 'react';
import React, { useRef } from 'react';

import { useAppState } from '@/overmind';

function ProductSearch(props: { onChange: Dispatch<SetStateAction<string>> }) {
  const textInputRef = useRef<HTMLInputElement>(null);
  const [showX, setShowX] = React.useState(false);
  const { leftLayout } = useAppState();

  const handleOnChange = (value: string) => {
    props.onChange(value);
    if (value.length > 0 && !showX) setShowX(true);
    if (value.length === 0 && showX) setShowX(false);
  };

  const handleClickX = () => {
    if (textInputRef.current) {
      textInputRef.current.value = '';
      handleOnChange('');
    }
  };

  return (
    <Box
      css={{
        position: 'absolute',
        zIndex: '101',
        top: '22px',
        right: leftLayout ? '50px' : '250px',
        width: '190px'
      }}
    >
      <FormField as={Flex} flow="column" gap="spacingXs">
        <TextInput
          leadingVisual={<SvgIcon iconName="search" />}
          placeholder="Find solution"
          size="small"
          onChange={e => handleOnChange(e.target.value)}
          ref={textInputRef}
          trailingVisual={
            showX ? (
              <Button
                withLoneIcon
                size="xSmall"
                variant="ghost"
                margin="none"
                shape="circle"
                onClick={handleClickX}
                style={{
                  padding: '2px'
                }}
              >
                <SvgIcon iconName={'close'} />
              </Button>
            ) : (
              <></>
            )
          }
        />
      </FormField>
    </Box>
  );
}

export default ProductSearch;
