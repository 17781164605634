import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Link,
  Text
} from '@volue/wave-react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import ProductDescription from '@/components/Shared/ProductDescription';
import type { ProductSummary } from '@/overmind/types';

export type ProductCardHandle = {
  getProductId: () => string;
  getHeroImageElement: () => HTMLImageElement;
};

type Props = {
  productSummary: ProductSummary;
};

type ProductCardLabel = {
  condition: () => boolean;
  text: string;
  backgroundColor: string;
  color: string;
};

function productCardLeftLabels(
  productSummary: ProductSummary
): ProductCardLabel[] {
  return [
    {
      condition: () => productSummary.newsLabel !== '',
      text: productSummary.newsLabel,
      backgroundColor: '#d95f59',
      color: '#ffffff'
    },
    {
      condition: () => productSummary.dealType === 'Trial',
      text: 'Trial',
      backgroundColor: '$backgroundNeutralStrong',
      color: '#ffffff'
    },
    {
      condition: () => productSummary.dealType === 'Subscription',
      text: 'Subscription',
      backgroundColor: '$backgroundNeutralStrong',
      color: '#ffffff'
    }
  ];
}

const ProductCard = forwardRef<ProductCardHandle, Props>((props, ref) => {
  const heroImageRef = useRef<HTMLImageElement>(null);
  const { productSummary } = props;

  useImperativeHandle(ref, () => ({
    getProductId() {
      return productSummary.id;
    },
    getHeroImageElement() {
      return heroImageRef.current!;
    }
  }));

  return (
    <Card
      key={productSummary.name}
      elevated
      gap="spacingS"
      css={{
        padding: '0',
        margin: '$spacingS'
      }}
    >
      <Card.Header css={{ padding: '0' }}>
        <img
          src={productSummary.heroImageUrl}
          ref={heroImageRef}
          style={{
            borderRadius: '12px 12px 0px 0px',
            overflow: 'hidden',
            padding: '0',
            width: '100%',
            aspectRatio: '2.333',
            backgroundColor: '#f9fafb'
          }}
        />
        {renderLeftLabels(productSummary)}
        {renderNotificationLabel(productSummary.notificationLabel)}
      </Card.Header>
      <Card.Body>
        <Box
          css={{
            padding: '0px 0px',
            margin: '0',
            height: '7rem',
            maxWidth: '100%'
          }}
        >
          <Heading as="h2" variant="delta">
            {productSummary.name}
          </Heading>
          <Text
            as="div"
            fontSize="small"
            css={{
              height: '80px',
              overflow: 'hidden',
              marginTop: '5px'
            }}
          >
            {productSummary.description}
          </Text>
        </Box>
      </Card.Body>
      <Card.Footer
        variant="withBackground"
        css={{
          margin: '8px 0px',
          height: '3.5rem',
          backgroundColor: '$colorWhite'
        }}
      >
        <Flex main="space-between" css={{ margin: '0px 0px' }}>
          <ProductDescription
            id={productSummary.id}
            label="Details"
            variant="outline"
          />
          {productSummary.dealType === 'None' ? (
            <></>
          ) : (
            <Link href={productSummary.launchButton.url}>
              <Button variant="strong" size={'small'} isNarrow>
                {productSummary.launchButton.label}
              </Button>
            </Link>
          )}
        </Flex>
      </Card.Footer>
    </Card>
  );
});

const renderLeftLabels = (productSummary: ProductSummary) => {
  return (
    <Box
      css={{
        position: 'absolute',
        top: '16px',
        left: '12px'
      }}
    >
      {productCardLeftLabels(productSummary).map((l, index) =>
        l.condition() ? (
          <Box
            key={index}
            css={{
              backgroundColor: l.backgroundColor,
              color: l.color,
              fontSize: '11pt',
              borderRadius: '12px',
              padding: '2px 8px'
            }}
          >
            <Text as="div" fontSize="xSmall">
              {l.text}
            </Text>
          </Box>
        ) : null
      )}
    </Box>
  );
};

const renderNotificationLabel = (text: string) => {
  if (!text) return null;
  return (
    <Box
      css={{
        position: 'absolute',
        top: '16px',
        right: '12px'
      }}
    >
      <Box
        css={{
          backgroundColor: '#082b33',
          borderColor: '#c2cdd0',
          border: '1px solid #c2cdd0',
          color: '#ffffff',
          fontSize: '11pt',
          borderRadius: '12px',
          padding: '2px 8px'
        }}
      >
        <Text as="div" fontSize="xSmall">
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export default ProductCard;
