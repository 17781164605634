/**
 * App's entry point
 */
import './globals.css';

// polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import { StrictMode } from 'react';
import { render } from 'react-dom';

import { ROOT_ELEMENT_ID } from './common/constants';
import App from './components/App';
import { config } from './overmind';

const rootElement = document.getElementById(ROOT_ELEMENT_ID);

const overmind = createOvermind(config, { devtools: !__PROD__ });

render(
  <StrictMode>
    <Provider value={overmind}>
      <App />
    </Provider>
  </StrictMode>,
  rootElement
);

// When this module runs again "createOvermind" is run
// and it automatically reconfigures
if (__DEV__ && module?.hot) {
  module.hot.accept();
}
