import { Flex, Heading } from '@volue/wave-react';

type Props = {
  title: string;
};

function ProductTopBar(props: Props) {
  return (
    <Flex
      padding="spacingL"
      css={{
        backgroundColor: '$colorPrimary100',
        color: '$colorWhite',
        width: '100%',
        height: '64px',
        position: 'fixed',
        top: '0',
        zIndex: '100'
      }}
    >
      <Heading as="h2">{props.title}</Heading>
    </Flex>
  );
}

export default ProductTopBar;
