import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import type { Overmind } from 'overmind';
import { pipe, catchError } from 'overmind';

import type { Context } from '.';
import type { Params } from './effects/router';
import { AbortError, UnexpectedStateError } from './errors';
import * as internalActions from './internalActions';
import { routes } from './machines/router';
import type { Route } from './machines/router';

export const internal = internalActions;

export const onInitializeOvermind = pipe(
  async (
    { effects, actions, state }: Context,
    // @ts-expect-error unused
    overmindInstance: Overmind<Context>
  ) => {
    effects.api.initialize(
      {
        baseURL: `${__APP_URL__}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      },
      (response: AxiosResponse) => {
        if (
          state.signedIdUser &&
          response.status !== 401 &&
          state.sessionExpiryInSeconds
        ) {
          actions.setSessionExpiry(
            new Date(Date.now() + Number(state.sessionExpiryInSeconds) * 1000)
          );
        }
      }
    );

    effects.router.initialize({
      [routes.home]: () =>
        actions.pageOpened({
          page: 'products',
          query: { productLine: 'Insight' }
        }),
      [routes.products]: actions.marketplace.productsRouted,
      // [routes.marketplace]: actions.marketplace.marketplaceRouted,
      [routes.myproducts]: actions.myProducts.myProductsRouted,
      // [routes.mysubscriptions]: () =>
      //  actions.internal.setRoute({ route: 'mysubscriptions' }),
      [routes.usersettings]: () =>
        actions.internal.setRoute({ route: 'usersettings' }),
      [routes.thankyou]: () => actions.internal.setRoute({ route: 'thankyou' })
    });

    effects.signalR.initialize({
      hubName: 'signalRHub',
      onHubConnectionError: actions.internal.hubConnectionErrorReceived,
      onHubConnectionOpen: actions.internal.hubConnectionOpened,
      logger: effects.logger
    });

    await actions.marketplace.loadProductRegions();
  },
  internalActions.getSignedInUser,
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    if (error.response?.status === 401) {
      state.signedIdUser = undefined;
      state.signedIdUserReady = true;
    } else {
      actions.handleError(error);
    }
  })
);

export const setSessionExpiry = ({ state }: Context, value: Date) => {
  state.sessionExpiry = value;
};

export const setActiveTenant = ({ state }: Context, value: string) => {
  const tenants = state.signedIdUser?.tenants?.filter(t => t.id === value);
  if (tenants && tenants.length > 0 && state.signedIdUser) {
    localStorage.setItem('activeTenant', tenants[0]?.id ?? '');
    const tenantClone = { ...tenants[0] };
    state.signedIdUser.activeTenant = tenantClone;
  }
};

export const mainScrolled = ({ state }: Context, value: number) => {
  state.scroll = value;
};

export const changeLayout = ({ state }: Context, isLeftLayout: boolean) => {
  state.leftLayout = isLeftLayout;
};

export const signIn = ({ state }: Context) => {
  state.isLoading = true;
  state.signedIdUserReady = false;
  window.location.href = `/auth/login?returnUrl=${
    window.location.pathname + window.location.search
  }`;
};

export const signOut = ({ state }: Context) => {
  state.isLoading = true;
  state.signedIdUserReady = false;
  window.location.href = `/auth/logout?sid=${
    state.signedIdUser?.sid
  }&returnUrl=${window.location.pathname + window.location.search}`;
};

export const extendSession = async ({ effects }: Context) => {
  await effects.api.getUser();
};

export const getOnBoardingStatus = async ({ effects }: Context) => {
  const result = await effects.api.getOnBoardingStatus();
  return result;
};

export const registerCompanyName = async (
  { state, effects }: Context,
  companyName: string
) => {
  state.onBoardingStatus.hasBeenSentOnFrontend = true;
  const result = await effects.api.registerOnBoarding(companyName);
  return result;
};

export const signalRInitialized = async ({ state, effects }: Context) => {
  if (state.signalR.send('CONNECT').matches('CONNECTING')) {
    try {
      // to be enabled when signalR hub is configured and ready
      // await effects.signalR.connect();
      await effects.misc.wait(1000);
      state.signalR.send('CONNECTION_SUCCESS');
    } catch (error) {
      state.signalR.send('CONNECTION_ERROR', (error as Error).message);
    }
  }
};

export const pageReload = ({ effects, state }: Context) => {
  if (!state.currentPage) return;
  const { page, params, query, hash } = state.currentPage;
  effects.router.goTo({ url: routes[page], params, query, hash });
};

export const pageOpened = (
  { effects, state }: Context,
  {
    page,
    params,
    query,
    hash
  }: {
    page: Route;
    params?: Params;
    query?: Params;
    hash?: Params;
  }
) => {
  state.currentPage = { page, params, query, hash };
  effects.router.goTo({ url: routes[page], params, query, hash });
};

export const handleError = ({ state }: Context, error: Error | AxiosError) => {
  if (!(error instanceof UnexpectedStateError || error instanceof AbortError)) {
    let errors = [];

    if (axios.isAxiosError(error)) {
      if (error.response?.data?.errors) {
        errors = Object.keys(error.response?.data?.errors).map(
          k => error.response?.data?.errors[k]
        );
      } else if (error.response?.data?.message) {
        errors.push(error.response?.data?.message);
      } else if (typeof error.response?.data === 'string') {
        errors.push(error.response?.data);
      }
    }

    state.lastError = {
      message: error.message,
      code: axios.isAxiosError(error) ? error.response?.status ?? 0 : 0
    };
    state.isLoading = false;
  }
};

export const clearLastError = ({ state }: Context) => {
  state.lastError = undefined;
};
