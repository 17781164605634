import type { IContext } from 'overmind';
import {
  createStateHook,
  createActionsHook,
  createReactionHook,
  createEffectsHook
} from 'overmind-react';
import { merge, namespaced } from 'overmind/config';

import * as actions from './actions';
import * as effects from './effects';
import type { RouterMachine, SignalRMachine } from './machines';
import { createRouterMachine, createSignalRMachine } from './machines';
import type { Route } from './machines/router';
import * as marketplace from './namespaces/marketplace';
import * as myProducts from './namespaces/myproducts';
import { OnBoardingRequestStatus } from './types';
import type { User } from './types';

type RootState = {
  router: RouterMachine;
  currentPage?: CurrentPage;
  signalR: SignalRMachine;
  isLoading: boolean;
  lastError?: LastError | undefined;
  isSigningIn: boolean;
  onBoardingStatus: OnBoardingStatus;
  signedIdUser?: User;
  signedIdUserReady: boolean;
  sessionExpiryInSeconds?: number;
  sessionExpiry?: Date;
  scroll: number;
  leftLayout: boolean;
  integrations: Integrations;
};

export type LastError = {
  message: string;
  code: number;
};

type Integrations = {
  identityAdminUrl: string;
};

type Params = Record<string, string | undefined>;

type CurrentPage = {
  page: Route;
  params?: Params;
  query?: Params;
  hash?: Params;
};

type OnBoardingStatus = {
  statusOnBackend: OnBoardingRequestStatus;
  hasBeenSentOnFrontend: boolean;
};

const router = createRouterMachine();

const rootState: RootState = {
  signalR: createSignalRMachine(),
  router,
  currentPage: { page: router.currentRoute },
  isLoading: false,
  isSigningIn: false,
  signedIdUserReady: false,
  scroll: 0,
  leftLayout: true,
  onBoardingStatus: {
    statusOnBackend: OnBoardingRequestStatus.unknown,
    hasBeenSentOnFrontend: false
  },
  integrations: {
    identityAdminUrl: ''
  }
};

export const config = merge(
  {
    // root `state` needs to be inlined because referencing it from a module leads
    // to merging fail with HMR
    state: rootState,
    actions,
    effects
  },
  namespaced({ marketplace, myProducts })
);

export type Context = IContext<{
  state: typeof config.state;
  actions: typeof config.actions;
  effects: typeof config.effects;
}>;

export type State = {
  current: string;
};

export const useAppState = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
export const useEffects = createEffectsHook<Context>();
export const useReaction = createReactionHook<Context>();
