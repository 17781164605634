import type { Statemachine } from 'overmind';
import { statemachine } from 'overmind';

import type { Params } from '../effects/router';

export const routes = {
  home: '/',
  // marketplace: '/marketplace',
  products: '/products',
  myproducts: '/myproducts',
  // mysubscriptions: '/mysubscriptions',
  usersettings: '/usersettings',
  thankyou: '/thankyou'
} as const;

export type Route = keyof typeof routes;

type States =
  | {
      current: 'VALID_PAGE';
    }
  | {
      current: 'PAGE_NOT_FOUND';
    };

type BaseState = {
  currentRoute: Route;
  params: Params;
};

export type ChangeRoutePayload = {
  route: Route;
  params?: Params;
};

type Events =
  | {
      type: 'CHANGE_ROUTE';
      data: ChangeRoutePayload;
    }
  | {
      type: 'NOT_FOUND_ROUTED';
    };

export type RouterMachine = Statemachine<States, Events, BaseState>;

export const routerMachine = statemachine<States, Events, BaseState>({
  VALID_PAGE: {
    CHANGE_ROUTE: ({ route, params = {} }, { current }) => ({
      current,
      currentRoute: route,
      params
    }),
    NOT_FOUND_ROUTED: () => ({
      current: 'PAGE_NOT_FOUND'
    })
  },
  PAGE_NOT_FOUND: {
    CHANGE_ROUTE: ({ route, params = {} }) => ({
      current: 'VALID_PAGE',
      currentRoute: route,
      params
    })
  }
});

export const createRouterMachine = () => {
  return routerMachine.create(
    { current: 'PAGE_NOT_FOUND' },
    {
      currentRoute: 'usersettings',
      params: {}
    }
  );
};
