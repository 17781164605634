import {
  AppFrame,
  Avatar,
  Box,
  Flex,
  Logo,
  Menu,
  Placement,
  SidebarNavigation,
  SIDEBAR_NAVIGATION_WIDTH_EXPANDED,
  styled
} from '@volue/wave-react';
import queryString from 'query-string';
import type { ReactNode } from 'react';
import { useState, useMemo } from 'react';

import { useActions, useAppState } from '@/overmind';

import Spinner from '../Shared/Spinner';
import TenantSwitch from '../Shared/TenantSwitch';

const UserAvatar = styled(Avatar, {
  mx: '$spacingS'
});

function determineNavigationIsActive(
  currentRoute: string,
  to: string,
  props: Record<string, any>
) {
  const queryParams = queryString.parse(window.location.search);
  if (currentRoute !== to) return false;
  if (props.query) {
    for (const prop in props.query) {
      if (queryParams[prop] !== props.query[prop]) {
        return false;
      }
    }
    for (const prop in queryParams) {
      if (queryParams[prop] !== props.query[prop]) {
        return false;
      }
    }
  } else if (queryParams && Object.keys(queryParams).length > 0) {
    return false;
  }
  return true;
}

// @ts-ignore
function SidebarNavigationItem({ to, ...props }) {
  const { pageOpened } = useActions();
  const { currentRoute } = useAppState().router;

  // const isActive = currentRoute === to;
  const isActive = determineNavigationIsActive(currentRoute, to, props);
  const css = isActive ? { fontWeight: 'bold' } : {};

  return (
    <SidebarNavigation.Item
      // @ts-ignore
      to={to}
      isActive={isActive}
      css={css}
      onClick={() => {
        if (props.query) {
          pageOpened({
            page: to,
            query: props.query,
            hash: props.hash
          });
        } else {
          pageOpened({ page: to, hash: props.hash });
        }
      }}
      {...props}
    />
  );
}

export default function AppLayoutLeft({ children }: { children: ReactNode }) {
  const { signOut, signIn, mainScrolled } = useActions();
  const { signedIdUser } = useAppState();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  // @ts-ignore
  const menuItems = useMemo<SidebarNavigationItem[]>(() => {
    return [
      /* {
        label: 'Home',
        iconName: 'home',
        to: 'home'
      }, 
      {
        label: 'Marketplace',
        iconName: 'shield',
        to: 'marketplace'
      }, */
      {
        label: 'Marketplace',
        iconName: 'home',
        to: 'products',
        query: { productLine: 'Insight' }
      },
      {
        label: 'Short Term',
        iconName: 'timeAlarm',
        to: 'products',
        query: { productLine: 'Insight', category: 'ShortTerm' }
      },
      {
        label: 'Mid Term',
        iconName: 'time',
        to: 'products',
        query: { productLine: 'Insight', category: 'MidTerm' }
      },
      /* {
        label: 'Long Term',
        iconName: 'calendar',
        to: 'products',
        query: 'Insight',
        hash: 'LongTerm'
      }, */
      {
        label: 'Fuel',
        iconName: 'turbine',
        to: 'products',
        query: { productLine: 'Insight', category: 'Fuel' }
      }
      /* {
        label: 'Construction',
        iconName: 'equipment',
        to: 'products',
        query: 'Construction'
      },
      {
        label: 'Energy Solutions',
        iconName: 'barChart1',
        to: 'products',
        query: 'Energy Solutions'
      }, 
      {
        label: 'My applications',
        iconName: 'download',
        to: 'myproducts'
      } */
    ];
  }, []);

  return (
    <AppFrame
      css={{ isolation: 'isolate' }}
      className="vh100"
      style={{ position: 'relative' }}
    >
      <AppFrame.HeaderSlot
        as={Flex}
        style={{ position: 'static' }}
        main="space-between"
      />
      <AppFrame.Sidebar>
        <SidebarNavigation
          aria-label="Main navigation"
          withSeparator
          mode="pushContent"
          onIsExpandedChange={setIsSidebarExpanded}
        >
          <SidebarNavigation.Header>
            <Logo
              variant={isSidebarExpanded ? 'wordmark' : 'icon'}
              label="Volue logo"
            />
          </SidebarNavigation.Header>
          <SidebarNavigation.Body>
            <SidebarNavigation.Section title="">
              <SidebarNavigation.Items>
                {menuItems.map(item => {
                  return (
                    <SidebarNavigationItem
                      key={item.label}
                      iconName={item.iconName}
                      label={item.label}
                      to={item.to}
                      query={item.query}
                      hash={item.hash}
                    />
                  );
                })}
              </SidebarNavigation.Items>
            </SidebarNavigation.Section>
          </SidebarNavigation.Body>
          <SidebarNavigation.Footer>
            <SidebarNavigation.Section title="Profile">
              <SidebarNavigation.Items>
                {signedIdUser === undefined ? (
                  <SidebarNavigation.Item
                    iconName="signIn"
                    label="Log In"
                    onClick={() => signIn()}
                  />
                ) : (
                  <></>
                )}
                {signedIdUser !== undefined ? (
                  <>
                    <SidebarNavigationItem
                      key="myapplications"
                      iconName="download"
                      label="My applications"
                      to="myproducts"
                    />
                    {/* <SidebarNavigationItem
                      key="mysubscriptions"
                      iconName="edit"
                      label="My subscriptions"
                      to="mysubscriptions" /> */}
                    <Menu>
                      <SidebarNavigation.Item
                        as={Menu.Trigger}
                        leadingVisual={
                          <Avatar of={signedIdUser?.displayName}>
                            <Avatar.Initials />
                          </Avatar>
                        }
                        label={signedIdUser?.displayName}
                      />
                      <Menu.Content
                        placement={Placement.TOP_LEFT}
                        css={{
                          maxWidth: `calc(${SIDEBAR_NAVIGATION_WIDTH_EXPANDED} - 1rem)`
                        }}
                      >
                        <Flex gap="spacingM" padding="spacingS">
                          <UserAvatar of={signedIdUser?.userName}>
                            <Avatar.Initials />
                          </UserAvatar>
                          <Box>
                            <Box className="zeta mTxs">
                              {signedIdUser?.displayName}
                            </Box>
                            <Box className="zeta mTxs gray75">
                              {signedIdUser?.userName}
                            </Box>
                          </Box>
                        </Flex>
                        {(signedIdUser?.tenants?.length ?? 0) > 1 ? (
                          <>
                            <Menu.Separator />
                            <Flex
                              gap="spacingM"
                              padding="spacingS"
                              css={{
                                width: '100%',
                                position: 'relative',
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            >
                              <Box css={{ width: '100%' }}>
                                <TenantSwitch />
                              </Box>
                            </Flex>
                          </>
                        ) : null}
                        <Menu.Separator />
                        <Menu.Item intent="danger" onSelect={() => signOut()}>
                          {'Log Out'}
                        </Menu.Item>
                      </Menu.Content>
                    </Menu>
                  </>
                ) : null}
              </SidebarNavigation.Items>
            </SidebarNavigation.Section>
          </SidebarNavigation.Footer>
          <SidebarNavigation.Toggle label="Toggle panel" />
        </SidebarNavigation>
      </AppFrame.Sidebar>
      <AppFrame.Main
        id="mainFrame"
        onScroll={event => {
          mainScrolled(event.currentTarget.scrollTop);
        }}
        css={{
          backgroundColor: '$colorGray5',
          display: 'grid',
          placeItems: 'center'
        }}
      >
        {children}
      </AppFrame.Main>
      <Spinner />
    </AppFrame>
  );
}
